const Socials = () => {
    return(
        <div className="predictions container">
            <div className="container__links">
                <a className="links__Link" href="https://www.twitch.tv/mysticmike60t">Twitch</a>
            </div>
        </div>
    )
}

export default Socials;