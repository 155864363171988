import React from 'react'
import { Link } from 'react-router-dom'

const Predictions = () => {
  return (
    <div className="predictions container">
        <div className="container__links">
            <Link className="links__Link" to="/predictions/wipe">Wipe</Link>
            <Link className="links__Link" to="/predictions/0.14.0.0">0.14.0.0</Link>
        </div>
    </div>
  )
}

export default Predictions