import React, { useEffect, useState } from "react";
import { getArenaImages } from "../../../hooks/contentful";
import LoadingSub from "../../global/LoadingSub";

const ArenaInfo = () => {
  const [arenaImages, setArenaImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getArenaImages()
      .then((data) => {
        setArenaImages(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching arena images:", error.message);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <div className="wipe prediction container">
            <div className="container__info">
              <div className="info__p">
                <div className="p__images">
                    <LoadingSub />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="wipe prediction container">
            <div className="container__info">
              <div className="info__p">
                Released Images for Arena:
                <br />
                <br />
                <div className="p__images">
                  {arenaImages.map((imageEntry, entryIndex) => (
                    <React.Fragment key={entryIndex}>
                      {imageEntry.fields.cloudinaryMedia.map(
                        (media, mediaIndex) => (
                          <img
                            key={mediaIndex}
                            src={media.url}
                            alt={`Arena ${entryIndex}-${mediaIndex}`}
                          />
                        )
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ArenaInfo;
