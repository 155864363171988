import React from "react";
import PromoCode from "./modules/PromoCode";

const PromoCodes = () => {
  return (
    <div className="home container">
      <div className="container__grid pcodes">
        <div className="disclaimer">
          Click on the codes to copy them to your clipboard.
        </div>
        <PromoCode code="1CLICKDRESSUP" color="red" />
        <PromoCode code="BARMALEY" color="red" />
        <PromoCode code="S00NS00N" color="red" />
        <PromoCode code="TRAMBON" color="red" />
        <PromoCode code="PINEWOOD" color="red" />
      </div>
    </div>
  );
};

export default PromoCodes;
