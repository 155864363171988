import React, { useEffect, useState } from "react";
import $ from "jquery";

import { getPosts } from "../../../hooks/contentful";

import Posts from "../../posts/Posts";
import LoadingSub from "../../global/LoadingSub";

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPosts()
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        $(".e-h-o-l").css({ opacity: "1" });
        setIsLoading(false);
      });
  });

  if(isLoading) {
    return <LoadingSub />
  }
  return (
    <div className="home container">
      <div className="container__title">
        <div className="title__h1"></div>
      </div>
      <div className="container__grid">
        <Posts />
      </div>
      <div className="container__seperator"></div>
    </div>
  );
};

export default HomePage;
