import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { getPosts } from "../../hooks/contentful";
import LoadingSub from "../global/LoadingSub";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPosts()
      .then((data) => {
        // Sort the posts array by date in descending order
        const sortedPosts = data.toSorted(
          (a, b) => new Date(b.fields.date) - new Date(a.fields.date)
        );
        setPosts(sortedPosts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error.message);
        setIsLoading(false);
      });
  }, []);

  const toggleMore = (index) => {
    setPosts((prevPosts) => {
      const updatedPosts = [...prevPosts];
      updatedPosts[index].showMore = !updatedPosts[index].showMore;
      return updatedPosts;
    });
  };

  const isImage = (mediaItem) => {
    return mediaItem?.resource_type === "image" || false;
  };

  const isVideo = (mediaItem) => {
    return mediaItem?.resource_type === "video" || false;
  };

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }
  return (
    <>
      {isLoading ? ( // Render loading screen if isLoading is true
        <div className="loading-screen">
          <div
            className="main__section pointer-events_none"
            style={{ border: "none", outline: "none", paddingTop: "20px" }}
          >
            <LoadingSub />
          </div>
        </div>
      ) : (
        <>
          {posts?.map((post, key) => (
            <div className="grid__item" key={post.fields.slug}>
              <a className="item__a" href={post.fields.linkToSocial}>
                {post.fields.account}
              </a>
              {post.fields.displayText.length > 200 ? (
                <>
                  {post.showMore ? (
                    <>
                      <div className="item__p-container">
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          className="item__p"
                        >
                          {post.fields.displayText}
                        </ReactMarkdown>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="item__p-container"
                        style={{
                          WebkitMaskImage:
                            "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
                        }}
                      >
                        <ReactMarkdown className="item__p truncated">
                          {String(post.fields.displayText).slice(0, 200)}
                        </ReactMarkdown>
                      </div>
                      <button onClick={() => toggleMore(key)} className="more">
                        More
                      </button>
                    </>
                  )}
                </>
              ) : (
                <div className="item__p-container">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="item__p"
                  >
                    {post.fields.displayText}
                  </ReactMarkdown>
                </div>
              )}
              {post?.fields?.cloudinaryMedia?.map((mediaItem, index) => (
                <React.Fragment key={index}>
                  {isVideo(mediaItem) && (
                    <video width="80%" controls>
                      <source
                        src={`https://res.cloudinary.com/deffah4vw/video/upload/${mediaItem.public_id}.${mediaItem.format}`}
                        type={`video/${mediaItem.format}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {isImage(mediaItem) && (
                    <img
                      src={mediaItem?.url || ""}
                      alt=""
                      style={{ userSelect: "none" }}
                    />
                  )}
                </React.Fragment>
              ))}
              {post.fields.linkToPost.length >= 2 && (
                <a href={post.fields.linkToPost} className="item__link">
                  Link
                </a>
              )}
              {/* <Link to={post.fields.slug} style={{marginBottom:"20px"}}>
                More
              </Link> */}
              <span className="item__date">{post.fields.date}</span>
            </div>
          ))}
        </>
      )}
    </>
  );
}
