const client = require("contentful").createClient({
    space: "vr3cfs4wxlgi",
    accessToken: "S_vfjD-dw9Wnqj2lu9sMD8oavJEv6VRtiUpI09gQ0VQ",
});

const getPosts = () =>
    client.getEntries({
        content_type: "post", // Replace with your specific content type name
    })
        .then((response) => response.items);
const getArenaImages = () =>
    client.getEntries({
        content_type: "arenaImages", // Replace with your specific content type name
    })
        .then((response) => response.items);
const getSinglePost = async (slug) => {
    try {
        const response = await client.getEntries({
            content_type: 'post'
        });

        const matchingPosts = response.items.filter(item => item.fields.slug === slug);

        if (matchingPosts.length > 0) {
            return matchingPosts[0].fields;
        } else {
            console.error('Post not found.');
            return null;
        }
    } catch (error) {
        console.error('Error fetching single post:', error.message);
        throw error;
    }
};

// const getContactInfo = async (slug) => {
//   try {
//     const response = await client.getEntries({
//       content_type: "contact", // Replace 'YOUR_CONTENT_TYPE_NAME' with the name of your content type
//       "fields.slug[in]": slug,
//       limit: 1,
//     });

//     // console.log("API Response:", response); // Debugging: Log the entire API response

//     if (response.items.length > 0) {
//       return response.items[0].fields;
//     } else {
//       console.error("Post not found.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error fetching single post:", error.message);
//     throw error;
//   }
// };




export { getPosts, getSinglePost, getArenaImages } // getContactInfo