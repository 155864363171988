import React from 'react'

import HomePage from "./home/HomePage"
import InfoPage from './info/InfoPage'
import Predictions from "./info/Predictions"

const Home = () => {
  return (
    <HomePage/>
  )
}
const Info = () => {
  return(
    <InfoPage />
  )
}

export {Home, Info, Predictions}