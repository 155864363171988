import React from "react";
import { Link } from "react-router-dom";

const InfoPage = () => {
  return (
    <div className="info container">
      <div className="container__links">
        <Link to="/new/arena" className="links__Link">
          Arena
        </Link>
        <Link to="/archive/events" className="links__Link">
          Events
        </Link>
        <Link to="/predictions" className="links__Link">
          Predictions
        </Link>
        <Link to="/info/promo-codes" className="links__Link">
          Promo Codes
        </Link>
        <div className="seperator"></div>
        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQJdmmAN_se57eCI64MPWxnveqCCprDoA3CJr8ihQxt6pTeo251xXzUXmPgTaTbh98ADQcdpKPxWKhH/pubhtml?gid=0&single=true" className="links__Link">
          Banned Cheaters
        </a>
        <a href="https://docs.google.com/spreadsheets/u/1/d/1l_8zSZg-viVTZ2bavMEIIKhix6mFTXuVHWcNKZgBrjQ/htmlview" className="links__Link">
          Ammo (As of August 11th)
        </a>
        <a href="https://mapgenie.io/tarkov" className="links__Link" style={{display:"inline-block"}}>
          Mapgenie
        </a>
        <Link to="https://www.goontracker.com/" className="links__Link">
          Goon Tracker
        </Link>
        <Link to="https://eft.monster/" className="links__Link">
          EFT Monster
        </Link>
        <div className="seperator"></div>
        <a href="https://tarkov.support" className="links__Link">
          Tarkov Support (My other site)
        </a>
        <Link to="/info/socials" className="links__Link">
          My Socials
        </Link>
        {/* <Link to="https://tarkov.help/en/" className="links__Link">
          Tarkov Help
        </Link> */}
        {/* <Link to="https://tarkov.dev/" className="links__Link">
          Tarkov Dev
        </Link>
        <Link to="https://tarkovgear.com/" className="links__Link">
          Tarkov Gear
        </Link>
        <Link to="https://tarkov.guru/hideout-profit/" className="links__Link">
          Tarkov Guru
        </Link> */}
      </div>
      {/* <div className="info__container">
        <Outlet />
        <div className="container__seperator"></div>
      </div> */}
    </div>
  );
};

export default InfoPage;
