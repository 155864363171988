import React from 'react'
const PromoCode = ({code, color}) => {
  function copyText(text) {
    navigator.clipboard.writeText(text);
    alert("Copied " + text);
  }
  let statusResult;
  if (color === 'green') {
    statusResult = 'Active';
  }
  if (color === 'yellow') {
    statusResult = 'About to end';
  }
  if (color === 'red') {
    statusResult = 'Ended / Invalid';
  }
  if (color === 'green') {
    color = '#20f05e';
  }
  return (
    <div className="grid__item">
      <button className="item__button" onClick={function(e){copyText(code)}}>
        {code}
      </button>
      <div className="item__status" style={{color:color}}>
        {statusResult}
      </div>
    </div>
  )
}

export default PromoCode