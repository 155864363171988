import React from "react";

const Events = () => {
  return (
    <div className="info container">
      <div className="container__links" style={{ flexDirection: "column" }}>
        <a
          href="https://escapefromtarkov.fandom.com/wiki/Bloodhounds"
          className="links__Link"
        >
          <span style={{ "fontSize": "1.5em" }}>1.</span> Bloodhounds
        </a>
        <a href="/events/airdrops" className="links__Link">
          <span style={{ "fontSize": "1.5em" }}>2.</span> Airdrops
        </a>
        <a href="/events/cultists" className="links__Link">
          <span style={{ "fontSize": "1.5em" }}>3.</span> Cultists
        </a>
        <a href="/events/goons" className="links__Link">
          <span style={{ "fontSize": "1.5em" }}>4.</span> Goons
        </a>
      </div>
    </div>
  );
};

export default Events;
