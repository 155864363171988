import React from 'react'

const Wipe = () => {
  return (
    <div className="wipe prediction container">
        <div className="container__info">
            <p className="info__p">
                Wipe is estimated to happen at the end of June, and will most likely mark the release of Arena.
            </p>
        </div>
    </div>
  )
}

export default Wipe