import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Layout } from "./components/global/Global";

import { Home, Info, Predictions } from "./components/pages/Pages";

import Wipe from "./components/pages/info/p/Wipe";
import CONTENT_14 from "./components/pages/info/p/Content_0.14.0.0";
import ArenaInfo from "./components/pages/info/Arena";
import Socials from "./components/pages/info/Socials";

import "./styles/global/uni.scss";

import PromoCodes from "./components/pages/info/PromoCodes";
import Events from "./components/pages/info/Events";
import { Airdrops, Cultists, Goons } from "./components/pages/info/events/";

import burgerIncident from "./assets/MedalTVEscapeFromTarkov20230816212550_Trim.mp4";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Layout />}>
          <Route index exact element={<Home />} />
          <Route path="new">
            <Route path="arena" element={<ArenaInfo />} />
          </Route>
          <Route path="info">
            <Route index element={<Info />} />
            <Route path="promo-codes" element={<PromoCodes />} />
            <Route path="socials" element={<Socials />} />
          </Route>
          <Route path="predictions">
            <Route index element={<Predictions />} />
            <Route path="wipe" element={<Wipe />} />
            <Route path="0.14.0.0" element={<CONTENT_14 />} />
          </Route>
          <Route path="archive">
            <Route path="events">
              <Route index element={<></>} />
              <Route path="goons" element={<Goons />} />
              <Route path="cultists" element={<Cultists />} />
              <Route path="airdrops" element={<Airdrops />} />
            </Route>
            <Route path="info">
              <Route path="events" element={<Events />}>
                <Route path="airdrops" element={<></>} />
              </Route>
            </Route>
          </Route>
          <Route path="privacy" element={<>Privacy Policy</>} />
          <Route path="burger-incident" element={<div className="info container">
            <video width="80%" controls>
              <source src={burgerIncident} type="video/mp4" />
              </video>
          </div>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
