import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";

import layoutImage from "../../assets/images/icons8-dashboard-layout-24.png";

const Header = ({ toggleLayout }) => {
  return (
    <header className="header" id="header">
      <Link className="header__title" to="/">
        <span style={{ fontSize: "1.8em" }}>Zosd</span> on Discord
      </Link>
      <ul className="header__ul">
        <li className="ul__li">
          <Link to="/" className="li__Link">
            HOME
          </Link>
        </li>
        <li className="ul__li">
          <Link to="/info" className="li__Link">
            INFO
          </Link>
        </li>
        <li className="ul__li">
          <a href="mailto:reporter@tarkov.press" className="li__Link">
            EMAIL ME
          </a>
        </li>
      </ul>
      <button onClick={toggleLayout} aria-label="Toggle Layout" alt="Toggle Layout"><img src={layoutImage} alt="Toggle Layout Icon" /></button>
    </header>
  );
};

const Layout = () => {
  const [isAlternativeLayout, setIsAlternativeLayout] = useState(false);

  useEffect(() => {
    // Retrieve layout preference from local storage on page load
    const storedLayout = localStorage.getItem("alternativeLayout");
    if (storedLayout) {
      setIsAlternativeLayout(JSON.parse(storedLayout));
    }
  }, []);

  const toggleLayout = () => {
    setIsAlternativeLayout((prevLayout) => !prevLayout);
  };

  useEffect(() => {
    // Store layout preference in local storage whenever it changes
    localStorage.setItem("alternativeLayout", JSON.stringify(isAlternativeLayout));
  }, [isAlternativeLayout]);

  return (
    <>
      <Header toggleLayout={toggleLayout} />
      <div className={`body__container ${isAlternativeLayout ? "alternative-layout" : ""}`}>
        <Outlet />
      </div>
    </>
  );
};

export { Layout };
