import React from 'react'

const CONTENT_14 = () => {
  return (
    <div className="wipe prediction container">
        <div className="container__info">
            <div className="info__p">
                Content Update Prediction <span>0.14.0.0</span>
                <br/><br/>
                <div className="disclaimer">This prediction is a guess, the real content could be different.</div>
                <br/>
                <ul>
                    <li>Sreets of Tarkov Expansion </li>
                    <li>BTR on Streets of Tarkov</li>
                    <li>New boss for Streets of Tarkov</li>
                    <li>Vaulting</li>
                    <li>Armored plates for body armor and rework of hitboxes</li>
                    <li>Kit presets</li>
                    <li>Weapon animations when interacting with the cover</li>
                    <li>Left shoulder shooting</li>
                    <li>Hideout expansion</li>
                    <li>Randomized loot containers</li>
                    <li>Rework of recoil mechanics of all weapon types</li>
                    <li>Quick pistol transition</li>
                    <li>Suppressor durability, sound variations, subsonic ammunition</li>
                    <li>Ammunition loading interface</li>
                    <li>Sight brightness adjustments</li>
                    <li>User interface rework</li>
                    <li>Quest rebalance</li>
                    <li>Rebalance of levels, skills and mastering</li>
                    <li>Bipods and stabilizing firearms with cover</li>
                    <li>Choosing which body part to heal via hotkey</li>
                    <li>Helmet flashlight</li>
                    <li>Meds and food consumption upon double click</li>
                    <li>AK-12, PKM, RPD, [A]SVT-40, 9A-91, UBGL for SCAR</li>
                    <li>Other</li>
                </ul>
                <br/>
                <br/>
            </div>
        </div>
    </div>
  )
}

export default CONTENT_14