// Component to display a loading animation with four circles.

const LoadingSub = () => (
    <>
      <div className="loading-sub__box">
        {/* Container to hold the loading animation */}
        <div className="loading-sub__container">
          {/* Four circles to represent the loading animation */}
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
        </div>
      </div>
    </>
  );
  
  export default LoadingSub;
  