import image1 from "../../../../assets/images/2023-06-19[11-37]_296.6, -0.9, 52.2_0.1, -0.1, 0.0, 1.0_16.39 (0).png";
import image2 from "../../../../assets/images/2023-06-19[11-38]_235.3, 0.6, 63.6_0.3, -0.6, 0.3, 0.6_16.44 (0).png";
import image3 from "../../../../assets/images/2023-06-19[11-44] (0).png";

const Goons = () => {
  return (
    <div className="event container">
      <div className="container__info">
        <div className="custom__text__post">
          The Goons squad are currently spawning on Factory!
        </div>
      </div>
    </div>
  );
};
const Cultists = () => {
  return (
    <div className="event container">
      <div className="container__info">
        <div className="custom__text__post">
          <span className="gold">Mechanic:</span> new task titled{" "}
          <span className="gold">Failed Setup</span>. "Eliminate the group
          responsible for the marked circles (30)."
          <br />
          <br />
          You cannot kill scavs, or you have to restart the task.
          <br />
          <br />
          Cultists are currently as of writing this spawning{" "}
          <span className="gold">100%</span> of the time.
        </div>
      </div>
    </div>
  );
};
const Airdrops = () => {
  return (
    <div className="event container">
      <div className="container__info">
        <span className="custom__text__post">
          Time is in <span className="time">NAE</span>
          <br />
          <span className="time">11:00</span> - Large amount of airdrops in
          raid! This looks like a pre-wipe event!
          <br />
          <br />
          <span className="time">11:04</span> - Each airdrop reportedly contain
          2 "weapon cases", which you bring back to your stash and can "open"
          like loot-boxes. <br />
          <br />
          <div className="blur">
            <span className="time">11:07</span> - Issues have reportedly been
            fixed.
            <br />
            <br />
            <span className="time">11:11</span> - Message stating that the case
            cannot be opened now.
          </div>
          <br />
          <br />
          Each weapon case contains different items. These items all are for a
          single gun, which is given inside of the case. With the gun and
          attachments, also comes the highest penetration ammo for the caliber.
          The case also contains food, usually an MRE and Iskra. In addition to
          all of that, it contains medical supplies, in practice, it has given 2
          stimulants, 1 afak, 1 surv12 kit, and a random medical item.
          <br />
          <br />
          They drop on every map besides <span className="gold">
            Factory
          </span>{" "}
          and <span className="gold">Labs</span>. They spawn at random
          intervals, there is currently no set number of them that spawn per
          raid.
          <br />
          <br />
          <img src={image1} alt="" />
          <img src={image2} alt="" />
          <br />
          <br />
          Everything inside of backpack is from the case.
          <br />
          <br />
          <img src={image3} alt="" />
          <br />
          <br />
          <span className="comment">
            From:{" "}
            <a href="mailto:reporter@tarkov.press">reporter@tarkov.press</a>
          </span>
        </span>
      </div>
    </div>
  );
};

export { Goons, Cultists, Airdrops };
